var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('headPage'),_c('div',{staticClass:"box1",staticStyle:{"overflow":"hidden"}}),_vm._m(0),_c('div',{staticClass:"box4"},[_c('div',{staticClass:"about_address"},[_c('baidu-map',{staticClass:"map",attrs:{"center":{ lng: 120.786695, lat: 31.346959 },"zoom":17,"scroll-wheel-zoom":true,"mapClick":false,"ak":"yerwmLGH9KXed8G7ExpfT8E9hRNxasGO"},on:{"ready":_vm.handler}},[_c('bm-scale',{attrs:{"anchor":"BMAP_ANCHOR_TOP_RIGHT"}}),_c('bm-navigation',{attrs:{"anchor":"BMAP_ANCHOR_TOP_RIGHT"}}),_vm._l((_vm.markers),function(marker,index){return _c('div',{key:marker.id},[_c('bm-marker',{attrs:{"position":{ lng: marker.lng, lat: marker.lat },"title":marker.servestationname},on:{"click":function($event){return _vm.infoWindowOpen(marker)}}},[_c('bm-info-window',{attrs:{"title":marker.servestationname,"position":{ lng: marker.lng, lat: marker.lat },"show":marker.showFlag},on:{"close":function($event){return _vm.infoWindowClose(marker)}}},[_c('p',[_vm._v(" "+_vm._s(marker.servestationaddress)+" ")])]),_c('bm-label',{attrs:{"content":index + 1 + '',"labelStyle":{
                color: '#fff',
                fontSize: '12px',
                background: 'rgba(0, 0, 0, 0)',
                borderColor: 'rgba(0, 0, 0, 0)',
              },"offset":{ width: index >= 9 ? 0 : 4, height: 2 }}})],1)],1)})],2)],1),_vm._m(1)]),_c('footPage')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box2",staticStyle:{"overflow":"hidden"}},[_c('p',{staticClass:"right-title"},[_vm._v("MAGIC HOME")]),_c('div',{staticClass:"info"},[_c('div',{attrs:{"data-aos":"fade-right"}},[_c('div',{staticClass:"tit1"},[_c('p',{staticClass:"p1"},[_vm._v("MH享家")]),_c('p',{staticClass:"p2"},[_vm._v("公司介绍")])]),_c('div',{staticClass:"tit2"},[_c('p',{staticClass:"p1"},[_vm._v(" MH享家, 国内舒适智能系统解决方案领先服务商, 于2015年正式进入高端住宅市场。 服务于别墅/大宅客户人群, 为其提供全屋舒适、智能化服务。 ")]),_c('p',{staticClass:"p2"},[_vm._v(" MH以前沿技术和客户需求为驱动力,储备了大量核心技术,逐步布局于五恒系统、智能灯光、家庭影音、智慧控制等领域,致力于打造舒适、健康、智能、娱乐为一体的品质生态居住体验。 ")])])]),_c('div',{staticClass:"t3"},[_c('p',{staticClass:"p3"},[_vm._v("SETP 01")]),_c('p',{staticClass:"p4"},[_vm._v("土建")])]),_c('div',{staticClass:"t4"},[_c('p',{staticClass:"p3"},[_vm._v("SETP 05")]),_c('p',{staticClass:"p4"},[_vm._v("软装")])]),_c('div',{staticClass:"t5"},[_c('p',{staticClass:"p3"},[_vm._v("SETP 04")]),_c('p',{staticClass:"p4"},[_vm._v("施工")])]),_c('div',{staticClass:"t6"},[_c('p',{staticClass:"p3"},[_vm._v("SETP 03")]),_c('p',{staticClass:"p4"},[_vm._v("设计")])]),_c('div',{staticClass:"t7"},[_c('p',{staticClass:"p3"},[_vm._v("SETP 02")]),_c('p',{staticClass:"p5"},[_vm._v("MH")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"infoRight"},[_c('div',{staticClass:"tit3"},[_vm._v("MH享家")]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"rightBut"},[_c('p',{staticClass:"tit3-1"},[_vm._v("MH享家综合展厅地址:")]),_c('p',{staticClass:"tit3-2"},[_vm._v("江苏省苏州市园区汀兰巷192号沙湖天地B5栋4楼")]),_c('p',{staticClass:"tit3-1"},[_vm._v("MH享家实景展厅地址:")]),_c('p',{staticClass:"tit3-2"},[_vm._v("江苏省苏州市和雍锦园20栋")]),_c('p',{staticClass:"tit3-1"},[_vm._v("MH享家办公地址:")]),_c('p',{staticClass:"tit3-2"},[_vm._v("苏州市金枫南路599号12幢103、203室")])])])
}]

export { render, staticRenderFns }