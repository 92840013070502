<template>
  <div>
    <headPage />
    <div class="box1" style="overflow: hidden"></div>
    <!-- 公司介绍 -->
    <div class="box2" style="overflow: hidden">
      <p class="right-title">MAGIC HOME</p>
      <div class="info">
        <div data-aos="fade-right">
          <div class="tit1">
            <p class="p1">MH享家</p>
            <p class="p2">公司介绍</p>
          </div>
          <div class="tit2">
            <p class="p1">
              MH享家, 国内舒适智能系统解决方案领先服务商,
              于2015年正式进入高端住宅市场。 服务于别墅/大宅客户人群,
              为其提供全屋舒适、智能化服务。
            </p>
            <p class="p2">
              MH以前沿技术和客户需求为驱动力,储备了大量核心技术,逐步布局于五恒系统、智能灯光、家庭影音、智慧控制等领域,致力于打造舒适、健康、智能、娱乐为一体的品质生态居住体验。
            </p>
          </div>
        </div>
        <div class="t3">
          <p class="p3">SETP 01</p>
          <p class="p4">土建</p>
        </div>

        <div class="t4">
          <p class="p3">SETP 05</p>
          <p class="p4">软装</p>
        </div>
        <div class="t5">
          <p class="p3">SETP 04</p>
          <p class="p4">施工</p>
        </div>
        <div class="t6">
          <p class="p3">SETP 03</p>
          <p class="p4">设计</p>
        </div>
        <div class="t7">
          <p class="p3">SETP 02</p>
          <p class="p5">MH</p>
        </div>
      </div>
    </div>
    <!-- 地图 -->
    <div class="box4">
      <div class="about_address">
        <baidu-map
          :center="{ lng: 120.786695, lat: 31.346959 }"
          :zoom="17"
          class="map"
          　
          @ready="handler"
          :scroll-wheel-zoom="true"
          :mapClick="false"
          ak="yerwmLGH9KXed8G7ExpfT8E9hRNxasGO"
          　　
        >
          <!-- 比例尺控件，注册此组件才会在地图上显示比例尺 -->
          <bm-scale anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-scale>
          <!-- 缩放控件，注册此组件才会显示拖放进度 -->
          <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
          <div v-for="(marker, index) in markers" :key="marker.id">
            　　　　　　　　　<!-- 标记点组件 -->
            <bm-marker
              :position="{ lng: marker.lng, lat: marker.lat }"
              :title="marker.servestationname"
              @click="infoWindowOpen(marker)"
            >
              　　　　　　　　　　<!-- 信息窗体组件 -->
              <bm-info-window
                :title="marker.servestationname"
                :position="{ lng: marker.lng, lat: marker.lat }"
                :show="marker.showFlag"
                @close="infoWindowClose(marker)"
              >
                <p>
                  {{ marker.servestationaddress }}
                </p>
              </bm-info-window>
              　　　　　　　　　　<!-- 标签组件 -->
              <bm-label
                :content="index + 1 + ''"
                :labelStyle="{
                  color: '#fff',
                  fontSize: '12px',
                  background: 'rgba(0, 0, 0, 0)',
                  borderColor: 'rgba(0, 0, 0, 0)',
                }"
                :offset="{ width: index >= 9 ? 0 : 4, height: 2 }"
              />
            </bm-marker>
          </div>
        </baidu-map>
      </div>
      <div class="infoRight">
        <div class="tit3">MH享家</div>
        <div class="line"></div>
        <div class="rightBut">
          <p class="tit3-1">MH享家综合展厅地址:</p>
          <p class="tit3-2">江苏省苏州市园区汀兰巷192号沙湖天地B5栋4楼</p>
          <p class="tit3-1">MH享家实景展厅地址:</p>
          <p class="tit3-2">江苏省苏州市和雍锦园20栋</p>
          <p class="tit3-1">MH享家办公地址:</p>
          <p class="tit3-2">苏州市金枫南路599号12幢103、203室</p>
        </div>
      </div>
    </div>
    <footPage />
  </div>
</template>
<script>
import headPage from "@/components/head.vue";
import footPage from "@/components/foot.vue";
// import case from ""
export default {
  data() {
    return {
      center: {
        lng: 120.786695,
        lat: 31.346959,
      },
      zoom: 15,
      // 地图标记点数据
      markers: [
        {
          lng: 120.786695,
          lat: 31.346959,
          servestationaddress: "MH享家综合展厅",
          showFlag: false, //flag放在每一条数据里
        },
      ],
    };
  },
  methods: {
    jump(url) {
      this.$router.push(url);
    },
    goMap() {
      var url = "https://j.map.baidu.com/0c/MEZf";
      window.open(url, "_blank");
    },
    // 地图初始化完成回调
    handler({ BMap, map }) {
      this.center.lng = 120.786695;
      this.center.lat = 31.346959;
    },
    // 关闭标记详情
    infoWindowClose(marker) {
      marker.showFlag = false;
    },
    // 打开标记详情
    infoWindowOpen(marker) {
      marker.showFlag = !marker.showFlag;
    },
  },
  components: {
    headPage,
    footPage,
    BaiduMap: () => import("vue-baidu-map/components/map/Map.vue"),
    BmNavigation: () =>
      import("vue-baidu-map/components/controls/Navigation.vue"), //缩放组件
    BmScale: () => import("vue-baidu-map/components/controls/Scale.vue"), //比例尺
    BmMarker: () => import("vue-baidu-map/components/overlays/Marker.vue"), //标记点的组件
    BmLabel: () => import("vue-baidu-map/components/overlays/Label.vue"), //标签组件，展示在标记点中的数字
    BmInfoWindow: () =>
      import("vue-baidu-map/components/overlays/InfoWindow.vue"), // 信息窗体
  },
};
</script>
<style lang="scss" scoped>
.right-title {
  font-family: "SourceHanSansCN-Medium";
  transform: rotate(270deg);
  position: absolute;
  top: 429px;
  font-size: 178px;
  right: -490px;
  color: #d0cecf;
}
.line {
  height: 1px;
  width: 330px;
  background-color: #bcbcbc;
  margin-top: 22px;
  margin-bottom: 28px;
}
.tit3 {
  font-family: "SourceHanSansCN-Medium";
  font-size: 30px;
  color: #000;
}
.infoRight {
  text-align: left;
  float: left;
  margin-top: 63px;
  margin-left: 65px;
}
.tit3-1 {
  font-family: "SourceHanSansCN-Light";
  font-size: 16px;
  color: #a3a3a3;
}
.tit3-2 {
  font-family: "SourceHanSansCN-Light";
  font-size: 16px;
  color: #585858;
  width: 284px;
  margin-bottom: 22px;
}
.box1 {
  background-image: url(../assets/image/introduction/image5.png);
  background-size: cover;
  width: 100%;
  height: 540px;
}
.box2 {
  position: relative;
  background-image: url(../assets/image/introduction/image1-1.jpg);
  background-size: cover;
  width: 100%;
  height: 1113px;
}
.info {
  text-align: left;
}
.tit1 {
  font-family: "SourceHanSansCN-Medium";
  margin-left: 131px;
  font-size: 71px;
  margin-top: 65px;
  color: #d3b968;
  .p2 {
    color: #000;
    font-size: 63px;
    margin-top: -7px;
  }
}
.tit2 {
  text-align: justify;
  font-family: "SourceHanSansCN-Light";
  font-size: 16px;
  color: #050505;
  width: 397px;
  margin-left: 137px;
  margin-top: 42px;
  .p2 {
    margin-top: 24px;
    width: 405px;
  }
}
.t3 {
  position: absolute;
  right: 244px;
  top: 338px;
  text-align: left;
}
.p3 {
  font-family: "SourceHanSansCN-Light";
  font-size: 16px;
  color: #050505;
}
.p4 {
  font-family: "SourceHanSansCN-Medium";
  font-size: 37px;
  color: #000;
  margin-top: -8p;
}
.t4 {
  position: absolute;
  left: 354px;
  top: 874px;
  text-align: left;
}
.t5 {
  position: absolute;
  left: 641px;
  top: 845px;
  text-align: left;
}
.t6 {
  position: absolute;
  left: 902px;
  top: 746px;
  text-align: left;
}
.t7 {
  position: absolute;
  left: 1165px;
  top: 539px;
  text-align: left;
}
.p5 {
  font-size: 59px;
  margin-top: -17px;
  font-family: "SourceHanSansCN-Medium";
  color: #d2ab32;
}
.img1 {
  width: 988px;
  height: 434px;
  cursor: pointer;
  float: left;
}
.box3 {
  width: 100%;
  height: 434px;
}
.box4 {
  height: 420px;
}
.about_address {
  float: left;
  width: 65%;
  height: 400px;
}
.map {
  height: 420px;
}
</style>